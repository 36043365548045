
import { h, defineComponent, ref, onMounted, computed, watch, watchEffect, getCurrentInstance, onBeforeMount } from "vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import PolicyService from "@/core/services/car/PolicyService";
import { onBeforeRouteLeave, useRoute } from "vue-router";
import { ElDivider } from "element-plus";
import PolicyDocuments from "@/components/partials/policy/ViewPolicyDocuments.vue";
import DropzoneFile from "./DropZone.vue";
import ViewTempDocuments from "@/components/partials/policy/ViewTempDocuments.vue";
import { ElNotification } from "element-plus";
import { notif, roles } from '@/store/stateless/store';
import moment from "moment";
import router from "@/router";
import MasterService from '@/core/services/car/MasterService';
import { Mutations } from "@/store/enums/StoreEnums";
import CancelPolicyReqModal from "@/components/modals/forms/CancelPolicyModal.vue";
import RefundPolicyRequestModal from "@/components/modals/forms/RefundRequestModal.vue";
import { setPolicyClaim } from '@/store/composable/Policy'

interface PolicyBodyParam {
  policy_id: any;
}

export interface Document {
  document_id: any;
  type: any;
}

export interface CreditDebitDetail {
  invoice_id: any;
  credit_amount: any;
  credit_notes_no: any;
  debit_amount: any;
  debit_notes_no: any;
  endorsement_no: any;
}

interface UploadPolicyBodyParam {
  policy_id: any;
  policy_number: any;
  chassis_number: any;
  customer_name: any;
  policy_start_date: any;
  policy_end_date: any;
  insurance_payment: any;
  documents: Document[];
  credit_debit_details: CreditDebitDetail[];
  emirates_id:any;
  entity_type:any;
  is_discrepancy: boolean
  is_amended: number,
  is_vintage: number
  car_year: number,
  status: number
}
interface DownloadDocBodyParam{
    policy: Array<any>;
    temp:  Array<any>;
}
export default defineComponent({
  name: "upload-policy-documents",
  components: {
    PolicyDocuments,
    DropzoneFile,
    ViewTempDocuments,
    CancelPolicyReqModal,
    RefundPolicyRequestModal
  },
  data() {
    return {
      size: 10,
      spacer: h(ElDivider, { direction: "vertical" }),
    };
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const inst = getCurrentInstance()
    
    const customerId = ref("");
    const leadId = ref("");
    let data = new FormData();
    let policyBodyParam = {} as PolicyBodyParam;
    let policyEndDate = ref("");
    let tempDocs = ref<any>([]);
    // let policyDocuments = ref<any>(null);
    let policyEmailList = ref<any>([]);
    let uploadPolicyBodyParam = {} as UploadPolicyBodyParam;
    let downloadDocBodyParam = {} as DownloadDocBodyParam;
    policyBodyParam.policy_id = route.params.policyId;
    uploadPolicyBodyParam.policy_id = policyBodyParam.policy_id;
    const requiredDocument = [1,2,3,4,5,6,7,8,9]
    const requiredDocumentNC = [1,2,3,4,5,6,7,8,9,21]

    let editPolicyObj = computed(() => {
      const  data =  store.getters.getpolicyDetails; 
      
      // if(data && data.car_lead_invoices_edit_policy) {
      //   data.car_lead_invoices_edit_policy.map(x => {
      //     if(x.is_policy_saved === 0 && x.debit_amount === "0.00" && ["2", "4", "5"].includes(x.amend_type)) {
      //       x.debit_amount = x.amount
      //     }
      //   })
      // }
      return data
    });

    uploadPolicyBodyParam = {
      policy_id: policyBodyParam.policy_id,
      policy_number: "",
      chassis_number: "",
      customer_name: "",
      policy_start_date: "",
      policy_end_date: "",
      insurance_payment: "",
      // credit_notes: "",
      // debit_notes: "",
      documents: [],
      credit_debit_details: [],
      emirates_id: "",
      entity_type:"",
      is_discrepancy: false,
      is_amended: 0,
      is_vintage: 0,
      car_year: 0,
      status: 0
    };
    let isFormValid = ref<boolean>(true);
    const rules = ref({
      policy_no: {
        show: false,
        message: "Please enter policy number",
      },

      chassis: {
        show: false,
        message: "Chassis number",
      },
      customer_name: {
        show: false,
        message: "Customer name on policy",
      },
      policy_start_date: {
        show: false,
        message: "Please enter policy start date",
      },
      insurance_payment: {
        show: false,
        message: "Please select insurance payment",
      },
      credit_note: {
        show: false,
        message: "Credit note",
      },
      credit_notes_no: {
        show: false,
        message: "Credit note no.",
      },
      debit_note: {
        show: false,
        message: "Please enter the debit & credit notes for the invoice",
      },
      debit_notes_no: {
        show: false,
        message: "Please enter the debit no.",
      },
      endorsement_no: {
        show: false,
        message: "Endorsement no.",
      },
      less_note: {
        show: false,
        message: 'Incorrect Debit Note entered'
      },
      emirates_id: {
        show: false,
        message: "Lebanese ID",
      },

      entity_type: {
        show: false,
        message: "Please select entity type",
      },
      amendment_start_date: {
        show: false,
        message: "Please select amendment start date",
      },
      amendment_end_date: {
        show: false,
        message: "Please select amendment end date",
      },
      credit_amount_error:{
        show: false,
        message: 'Incorrect amount'
      },
      debit_amount_error:{
        show: false,
        message: 'Incorrect amount'
      }
    });
    onMounted(() => {

      if(!roles.policiesAssigned.includes(roles.userId())) window.location.href = '/#/404'

      MasterService.getDocumentTypes({
                categories: 'standard'
            })
      
        MasterService.getCanxDocumentTypes({
            categories: 'cancellation'
      })
      MenuComponent.reinitialization();


      // 
      console.log("1");
      PolicyService.getDebitAmount();
      getPolicyDetails();

      getEmailList();
      Promise.all([PolicyService.getPolicyDocuments(policyBodyParam), MasterService.getPolicyStartDateDays()]).then(
        (data) => {
          // 
          console.log(data);
          // policyDocuments = store.getters.getPolicyDocs;
          // console.log(
          //   "================================================",
          //   policyDocuments
          // );
          
        }
      );
      
    });

    const getPolicyDetails = () => {
      const user = JSON.parse(window.localStorage.getItem('user') || '');

      console.log('policyBodyParam',policyBodyParam)
      Promise.all([PolicyService.getPolicyDetails(policyBodyParam)]).then(
        (data) => {
          // 
          console.log(store.getters.getpolicyDetails);
          setCurrentPageBreadcrumbs(`${[1,4, 14].includes(user.role_id) && editPolicyObj.value.status != 10 && !checkPolicyView.value ? 'Edit' : 'View'} Policy Details`, ["Policy"]);

          PolicyService.getTempPolicyDocuments({
            policy_id: null,
            lead_id: editPolicyObj.value.lead_id,
            categories: "standard",
          });

          let payment : any = ""
          if([1, 4, 14].includes(editPolicyObj.value.car_lead_invoice.payment_type)) {
            payment = 1
          } 

          if(editPolicyObj.value.car_lead_invoice.payment_type === 3) {
            payment = 2
          }

          if(!editPolicyObj.value.policy_start_date) editPolicyObj.value.policy_end_date = ''

          editPolicyObj.value.insurance_payment = editPolicyObj.value.insurance_payment ? editPolicyObj.value.insurance_payment : payment
          loading.value = editPolicyObj.value.car_lead_invoice && editPolicyObj.value.car_lead_invoice.is_discrepancy == 1 ? true : false;

        showExtensionModal()
          //Cancellation Reason
          MasterService.getCancellationReason();
          //Refund Reason
          MasterService.getReasonnReason();

          const customerNameRef:any = inst?.refs?.customerNameRef
          customerNameRef.focus()
    
        }
      );
    }

    onBeforeRouteLeave(() => {
      ElNotification.closeAll();
    })

    const debitValue = computed(() => {
      const totalInvoice = editPolicyObj.value.car_lead_invoices.reduce((total, item) => {
        return total += parseFloat(item.amount)
      }, 0)
      // const parentAmount = editPolicyObj.value.car_lead_invoice && editPolicyObj.value.car_lead_invoice.amount ? editPolicyObj.value.car_lead_invoice.amount : 0;
      return totalInvoice * (parseInt(store.getters.getDebitValue) / 100);
    });

    const percentage = computed(() => {
      return store.getters.getDebitValue;
    })

    const handlePolicyFileUpload = (event) => {
      let file = event.target.files[0];
      data.append("policy_document", file);
      console.log(data);
    };
    const handleCreditDebitFileUpload = (event) => {
      let file = event.target.files[0];
      data.append("credit_debit_document", file);
      console.log(data);
    };
    const handleOtherFileUpload = (event) => {
      // let files = [] as any;
      let files = event.target.files;
      for (let index = 0; index < event.target.files.length; index++) {
        data.append("other_documents[]", files[index]);
      }

      // event.target.files.forEach(function (file) {});

      // data.append("other_documents[]", files);
      console.log(data);
    };

    const policyDocuments = computed(() => {
      return store.getters.getPolicyDocs;
    });

    const tempDocuments = computed(() => {
      return store.getters.getTempPolicyDocs.filter(x => x.categories !== 'cancellation');
    });
    
    const downloadZip = () => {
      let policyDocArray = Array<any>();
      let tempPolicyDocArray = Array<any>();

      tempDocuments.value?.map((tempDoc) => {
        if(tempDoc?.is_temp == 1 && tempDoc?.document_type?.document_type == 'verification' ){
          tempPolicyDocArray.push(tempDoc.id);
        }
      })    
      policyDocuments.value.map((policyDoc) =>{
          if (policyDoc?.is_temp == 0 && policyDoc?.document_type?.document_type == 'verification'){
            policyDocArray.push(policyDoc.id);
          }
        });

        // console.log({
        //   tempDocuments: tempPolicyDocArray,
        //   policyDocuments: policyDocArray
        // })
    

      downloadDocBodyParam.policy = policyDocArray;
      downloadDocBodyParam.temp = tempPolicyDocArray;
      console.log(downloadDocBodyParam);
      PolicyService.downloadZip(downloadDocBodyParam);
    };

    tempDocs  = computed(() => {
      return store.getters.getTempPolicyDocs;
    });

    const cancellationDoc  = computed(() => {
      return store.getters.getTempPolicyCancelDocs.filter(x => x.categories === 'cancellation');
    });

    const getTempCancellationDocuments = () => {
      Promise.all([
        PolicyService.getTempPolicyCancelDocuments({
          lead_id: editPolicyObj.value.lead_id,
          categories: "cancellation",
        })
      ]).then((data) => {
        // tempDocs.value = computed(() => {
        //   return store.getters.getTempPolicyDocs;
        // });
      });
    };


    const assignBody = () => {
      isFormValid.value = true;
      uploadPolicyBodyParam.documents = [];
      uploadPolicyBodyParam.credit_debit_details = [];
      // tempDocs.value = store.getters.getTempPolicyDocs;
      // console.log(editPolicyObj);
      uploadPolicyBodyParam.entity_type = editPolicyObj.value.entity_type;
      uploadPolicyBodyParam.emirates_id = editPolicyObj.value.emirates_id;
      uploadPolicyBodyParam.policy_number = editPolicyObj.value.policy_number;
      uploadPolicyBodyParam.chassis_number = editPolicyObj.value.chassis_number;
      uploadPolicyBodyParam.customer_name = editPolicyObj.value.customer_name;
      uploadPolicyBodyParam.policy_start_date =
        editPolicyObj.value.policy_start_date;
      uploadPolicyBodyParam.policy_end_date =
        editPolicyObj.value.policy_end_date;
      uploadPolicyBodyParam.insurance_payment =
        editPolicyObj.value.insurance_payment;
      // uploadPolicyBodyParam.credit_notes = editPolicyObj.value.credit_notes;
      // uploadPolicyBodyParam.debit_notes = editPolicyObj.value.debit_notes;

    // if(tempDocs.value.length > 0) {
        tempDocs.value.forEach((doc) => {
          let document = {
            document_id: doc.id,
            type: doc.type,
          };
          uploadPolicyBodyParam.documents.push(document);
        });
        policyDocuments.value.forEach((doc) => {
          let document = {
            document_id: doc.id,
            type: doc.type,
          };
          uploadPolicyBodyParam.documents.push(document);
        });
      // } else {
      //   uploadedDocs.value.forEach((doc) => {
      //       let document = {
      //         document_id: doc.id,
      //       type: doc.type,
      //     };
      //     uploadPolicyBodyParam.documents.push(document);
      //   });
      // }

      editPolicyObj.value.car_lead_invoices_edit_policy.forEach((invoice) => {
        let data = {
          invoice_id: invoice.id,
          credit_amount: invoice.credit_amount,
          credit_notes_no: invoice.credit_notes_no,
          debit_amount: invoice.debit_amount,
          debit_notes_no: invoice.debit_notes_no,
          endorsement_no: invoice.endorsement_no,
          amend_type: invoice.amend_type,
          amendment_start_date: invoice.amendment_start_date,
          amendment_end_date: invoice.amendment_end_date,
          is_invoice_completed: invoice.is_policy_saved === 1 ? true : false
        };
        uploadPolicyBodyParam.credit_debit_details.push(data);
      });

      uploadPolicyBodyParam.is_amended = editPolicyObj.value.is_amended
    };
    const uploadPolicy = () => {
      resetValidation(); 
      assignBody();
      validateForm();

    if(editPolicyObj.value.status != 4) {
      // if(editPolicyObj.value.car_lead_invoice && editPolicyObj.value.car_lead_invoice.amend_type === "3" && !requiredDocumentNC.every(checkCompletedDocument) && !getDocumentTypeNotInCompletedStateNC().every(checkTempDocument)) {
      //       isFormValid.value = false
      //       window.scrollTo(250, 255)
      //       notif.simple('Please Note', 'warning', 'Kindly upload the required documents before you finally submit it for policy completion.', 0)
      //       return
      //   }

        if(editPolicyObj.value.is_amended === 1 && !isAmendValid.value){
          if(tempDocs.value && tempDocs.value.length === 0) {
            isFormValid.value = false
            window.scrollTo(250, 255)
            notif.simple('Please Note', 'warning', 'Kindly upload the required documents before you finally submit it for policy completion.', 0)
          }
            isFormValid.value = false
        }

        if(editPolicyObj.value.is_amended === 0 && !isAmendValid.value) {
          isFormValid.value = false
          notif.simple('Please Note', 'warning', 'Kindly upload the required documents before you finally submit it for policy completion.', 0)
        }

        if(editPolicyObj.value.is_amended === 1 && isAmendValid.value && tempDocs.value && extensionAndGE.value) {
          // console.log(getDocumentTypeNotInCompletedState());
          // if(tempDocs.value.filter(x => x.type === 1).length === 0) {
          //   isFormValid.value = false
          //   window.scrollTo(250, 255)
          //   notif.simple('Please Note', 'warning', 'Kindly upload the required documents before you finally submit it for policy completion.', 0)
          // }

          if(!requiredDocument.every(checkCompletedDocument) && !getDocumentTypeNotInCompletedState().every(checkTempDocument)) {
            isFormValid.value = false
            window.scrollTo(250, 255)
            notif.simple('Please Note', 'warning', 'Kindly upload the required documents before you finally submit it for policy completion.', 0)
          }

          if(!isAmendValid.value) {
            isFormValid.value = false
          }
        }
      }

      if (isFormValid.value) {

        if(discrepancyModal.value) {
          uploadPolicyBodyParam.is_discrepancy = true
          loading.value = true
          toFirst()
        } else {
          uploadPolicyBodyParam.is_discrepancy = false
        }

        if(editPolicyObj.value && editPolicyObj.value.car_lead && editPolicyObj.value.car_lead.is_vintage === 1) {
          uploadPolicyBodyParam.is_vintage = 1
          uploadPolicyBodyParam.car_year = parseInt(editPolicyObj.value.car_lead.car_year)
        }

        uploadPolicyBodyParam.status = editPolicyObj.value.status
        

        Promise.all([PolicyService.uploadPolicy(uploadPolicyBodyParam)]).then(
          (data) => {
            if (data[0].success) {
              notif.simple('Policy Completed', 'success', 'Policy successfully completed')
            }
            window.scrollTo(0, 0);
            toCustomerName();
            Promise.all([
              PolicyService.getPolicyDetails(policyBodyParam),
              PolicyService.getPolicyDocuments(policyBodyParam),
              PolicyService.getTempPolicyDocuments({
                policy_id: null,
                lead_id: editPolicyObj.value.lead_id,
                categories: "standard",
              }),
            ]);
            if(editPolicyObj.value.status == 4) {
              router.push({ name: 'policy-completed' });
            }
          }
        );

        
      } else {
        focusField()
      }
    };

    
    const saveAsDraft = () => {
      resetValidation(); 
      // console.log(policyDocuments)
      assignBody();
      validateSaveAsDraft();

      if (isFormValid.value) {
      assignBody();
        Promise.all([PolicyService.saveAsDraft(uploadPolicyBodyParam)]).then(
          (data) => {
            if (data[0].success) {
              // ElMessage({
              //   message: data[0].message,
              //   type: "success",
              // });
              notif.simple('Policy Saved to Draft', 'success', data[0].message)
              if(data[0].data.document_validation != '') {
                setTimeout(() => {
                  notif.simple('Please Note', 'warning', data[0].data.document_validation, 0)
                }, 1000);
              }
            }
            // call list temp and policy document
            Promise.all([
              PolicyService.getPolicyDocuments(policyBodyParam),
              PolicyService.getTempPolicyDocuments({
                policy_id: null,
                lead_id: editPolicyObj.value.lead_id,
                categories: "standard",
              }),
            ]);
          }
        )
      } else {
        focusField()
        // window.scrollTo(0, 250)
      }
    };
    const resetValidation = () => {
      rules.value.policy_no.show = false;
      rules.value.chassis.show = false;
      rules.value.customer_name.show = false;
      rules.value.policy_start_date.show = false;
      rules.value.insurance_payment.show = false;
      rules.value.credit_note.show = false;
      rules.value.entity_type.show = false;
      rules.value.debit_note.show = false;
      rules.value.less_note.show = false
      rules.value.emirates_id.show = false;
      rules.value.amendment_start_date.show = false;
      rules.value.amendment_end_date.show = false;
      isCreditValid.value = true;
      isDebitValid.value = true;
    }

    const validateForm = () => {
      var chassisRegex = /(?=.*[A-Z])(?=.*\d)(?=.{4}).*/g;
      var policyRegex = /(?=.*[A-Z])(?=.*\d)(?=.{1}).*/g;
      let decimalPlaces = /^[0-9]*(\.[0-9]{0,2})?$/;
      let numberContain = /[0-9]/;;
      let lettersContain = /[A-Za-z]/;

      

      // if(uploadPolicyBodyParam.policy_number && !lettersContain.test(uploadPolicyBodyParam.policy_number)) {
      //   rules.value.policy_no.message = 'Policy number must have atleast one character.';
      //   rules.value.policy_no.show = true;
      //     isFormValid.value = false;
      // }
      // console.log(uploadPolicyBodyParam);
      if (!uploadPolicyBodyParam.policy_number || (uploadPolicyBodyParam.policy_number && uploadPolicyBodyParam.policy_number.length < 10)) {
        if(uploadPolicyBodyParam.policy_number && uploadPolicyBodyParam.policy_number.length < 10) {
          rules.value.policy_no.message = 'Policy number must not less than 10 characters';
        }
        if(uploadPolicyBodyParam.policy_number == "") {
          rules.value.policy_no.message =  "Please enter policy number";
        }
        rules.value.policy_no.show = true;
          // policy_no.value.focus()
        isFormValid.value = false;
      }

      if (!uploadPolicyBodyParam.insurance_payment) {
        rules.value.insurance_payment.show = true;
        isFormValid.value = false;
        if(!rules.value.customer_name.show && 
            !rules.value.emirates_id.show &&
            !rules.value.policy_no.show && 
            !rules.value.policy_start_date.show && 
            !rules.value.chassis.show) {
          // insurance_payment.value.focus()
        }
      }

      
        // alert(uploadPolicyBodyParam.emirates_id.length)

        if(!uploadPolicyBodyParam.emirates_id) {
          rules.value.emirates_id.message = 'Please enter correct Lebanese ID';
          rules.value.emirates_id.show = true;
          isFormValid.value = false;
        }

      if (uploadPolicyBodyParam.emirates_id) {
        if(uploadPolicyBodyParam.emirates_id.length > 36) {
          rules.value.emirates_id.message = 'Lebanese ID must not be greater than 36';
          rules.value.emirates_id.show = true;
          isFormValid.value = false;
        } 
        if(uploadPolicyBodyParam.emirates_id.length < 10) {
          rules.value.emirates_id.message = 'Lebanese ID must not be less than 10';
          rules.value.emirates_id.show = true;
          isFormValid.value = false;
        } 
        
        if(!numberContain.test(uploadPolicyBodyParam.emirates_id) && !lettersContain.test(uploadPolicyBodyParam.emirates_id)) {
          rules.value.emirates_id.message = 'Please enter correct Lebanese ID';
          rules.value.emirates_id.show = true;
          isFormValid.value = false;
        }
      }

      if (!uploadPolicyBodyParam.customer_name) {
        rules.value.customer_name.show = true;
        isFormValid.value = false;
        // customer_name.value.focus()
      }
      if (!uploadPolicyBodyParam.policy_start_date) {
        rules.value.policy_start_date.show = true;
        isFormValid.value = false;
        if( !rules.value.customer_name.show && 
            !rules.value.emirates_id.show &&
            !rules.value.policy_no.show) {
              
              setTimeout(() => {
                // policy_start.value.isFocus()
              }, 500);
        }
      }
      
      if(debitValue.value > totalDebit.value) {
        rules.value.less_note.message = `Incorrect Debit Note entered (${percentage.value}%)`;
        rules.value.less_note.show = true;
        isFormValid.value = false;
      }

      if(getBackendIncorrectDebit.value) {
        rules.value.less_note.message = `Incorrect Debit Note entered (${percentage.value}%)`;
        rules.value.less_note.show = true;
        isFormValid.value = false;
      }

      if(uploadPolicyBodyParam.entity_type == "" || uploadPolicyBodyParam.entity_type == null) {
         rules.value.entity_type.show = true;
         isFormValid.value = false;
         if(!rules.value.customer_name.show && 
            !rules.value.emirates_id.show &&
            !rules.value.policy_no.show && 
            !rules.value.policy_start_date.show &&
            !rules.value.insurance_payment.show) {
          // entity_type.value.focus()
        }
      }

      if(uploadPolicyBodyParam.chassis_number  && (!lettersContain.test(uploadPolicyBodyParam.chassis_number) || !numberContain.test(uploadPolicyBodyParam.chassis_number))) {
        rules.value.chassis.message = 'Chassis number must be alphanumeric';
        rules.value.chassis.show = true;
          isFormValid.value = false;
          if(!rules.value.customer_name.show && 
            !rules.value.emirates_id.show &&
            !rules.value.policy_no.show && 
            !rules.value.policy_start_date.show) {
          // chasiss.value.focus()
        }
      }
      // && editPolicyObj.value.car_lead.is_vintage === 1
      if(editPolicyObj.value && editPolicyObj.value.car_lead && parseInt(editPolicyObj.value.car_lead.car_year) < 1981) {
        if (!uploadPolicyBodyParam.chassis_number || (uploadPolicyBodyParam.chassis_number && uploadPolicyBodyParam.chassis_number.length < 9 )) {
          // if(uploadPolicyBodyParam.chassis_number && uploadPolicyBodyParam.chassis_number.length < 9) {
          //   rules.value.chassis.message = 'Chassis number must not less than 9 and greater than 17';
          //   rules.value.chassis.show = true;
          //   isFormValid.value = false;
          // } 
          // if(uploadPolicyBodyParam.chassis_number && uploadPolicyBodyParam.chassis_number.length > 17) {
          //   rules.value.chassis.message = 'Chassis number must not less than 9 and greater than 17';
          //   rules.value.chassis.show = true;
          //   isFormValid.value = false;
          // } 
          if(uploadPolicyBodyParam.chassis_number == "") {
            rules.value.chassis.message = "Chassis number";
            rules.value.chassis.show = true;
            isFormValid.value = false;
          }
          
        }
      } else {
        if (!uploadPolicyBodyParam.chassis_number || (uploadPolicyBodyParam.chassis_number && uploadPolicyBodyParam.chassis_number.length < 17 )) {
          // if(uploadPolicyBodyParam.chassis_number && uploadPolicyBodyParam.chassis_number.length < 17) {
          //   rules.value.chassis.message = 'Chassis number must not less than 17 and greater than 20';
          //   rules.value.chassis.show = true;
          //   isFormValid.value = false;
          // } 
          // if(uploadPolicyBodyParam.chassis_number && uploadPolicyBodyParam.chassis_number.length > 20) {
          //   rules.value.chassis.message = 'Chassis number must not less than 17 and greater than 20';
          //   rules.value.chassis.show = true;
          //   isFormValid.value = false;
          // } 
          if(uploadPolicyBodyParam.chassis_number == "") {
            rules.value.chassis.message = "Chassis number";
            rules.value.chassis.show = true;
            isFormValid.value = false;
          }
        }
      }

      

      // if (uploadPolicyBodyParam.credit_notes == "") {
      //   rules.value.credit_note.show = true;
      //   isFormValid.value = false;
      // }
      // if (uploadPolicyBodyParam.debit_notes == "") {
      //   rules.value.debit_note.show = true;
      //   isFormValid.value = false;
      // }
      const indexCredit = [] as Array<number>;
      const indexCreditNo = [] as Array<number>;
      const indexDebit = [] as Array<number>;
      const indexDebitNo = [] as Array<number>;
      const endorsementNo = [] as Array<number>;
      const ammendmentStartDate = [] as Array<number>;
      const ammendmentEndDate = [] as Array<number>;
      editPolicyObj.value.car_lead_invoices_edit_policy.forEach((invoice, index) => {
        if(invoice.amend_type !== "1") {
          if(!["3", "4"].includes(invoice.amend_type)) {
            if (invoice.amount > 0 && (invoice.credit_amount === 0 || invoice.credit_amount === '0.00') && invoice.is_policy_saved === 0) {
              // rules.value.credit_note.show = true;
              // rules.value.credit_note.message = 'Credit note';
              // // creditIndex.value.push(index)
              // indexCredit.push(index)
              // isFormValid.value = false;
            }
            // if(invoice.credit_amount && !decimalPlaces.test(String(invoice.credit_amount))) {
            //   rules.value.credit_note.show = true;
            //   rules.value.credit_note.message = 'Please enter upto 2 decimal places only';
            //   isFormValid.value = false
            //   indexCredit.push(index)
            //   isCreditValid.value = false
            // }
            // if(!invoice.credit_notes_no && invoice.is_policy_saved === 0) {
            //   rules.value.credit_notes_no.show = true;
            //   rules.value.credit_notes_no.message = 'Credit note no.';
            //   isFormValid.value = false
            //   indexCreditNo.push(index)
            // }
            // to be added credit note no.
          }
          if (invoice.debit_amount === 0 || invoice.debit_amount === '0.00' && invoice.is_policy_saved === 0) {
            rules.value.debit_note.show = true;
            rules.value.debit_note.message = 'Debit note';
            // debitIndex.value.push(index)
            indexDebit.push(index)
            isFormValid.value = false;
          }
          if(invoice.debit_amount && !decimalPlaces.test(String(invoice.debit_amount)) && invoice.is_policy_saved === 0) {
            rules.value.debit_note.show = true;
            rules.value.debit_note.message = 'Please enter upto 2 decimal places only';
            isFormValid.value = false
            indexDebit.push(index)
            isDebitValid.value = false
          }
          // if(!invoice.debit_notes_no && invoice.is_policy_saved === 0) {
          //     rules.value.debit_notes_no.show = true;
          //     rules.value.debit_notes_no.message = 'Debit note no.';
          //     isFormValid.value = false
          //     indexDebitNo.push(index)
          //   }
          // if(invoice.amend_type !== '0' && (invoice.endorsement_no === '' || invoice.endorsement_no === null) && invoice.is_policy_saved === 0) {
          //     rules.value.endorsement_no.show = true;
          //     rules.value.endorsement_no.message = 'Endorsement no.';
          //     isFormValid.value = false
          //     endorsementNo.push(index)
          // }

          if(["4", "2", "5"].includes(invoice.amend_type) && parseFloat(invoice.amount) > 0 && parseFloat(invoice.amount) < parseFloat(invoice.debit_amount) && invoice.is_policy_saved === 0) {
            rules.value.debit_note.show = true;
            rules.value.debit_note.message = 'Debit note >  Invoice amount';
            indexDebit.push(index)
            isFormValid.value = false;
          }

          if(!["0", "1"].includes(invoice.amend_type) && invoice.is_policy_saved === 0 && !invoice.amendment_start_date) {
            rules.value.amendment_start_date.show = true;
            rules.value.amendment_start_date.message = 'Please enter amendment start date';
            ammendmentStartDate.push(index)
            isFormValid.value = false;
          }
          if(!["0", "1"].includes(invoice.amend_type) && invoice.is_policy_saved === 0 && !invoice.amendment_end_date) {
            rules.value.amendment_end_date.show = true;
            rules.value.amendment_end_date.message = 'Please enter amendment end date';
            ammendmentEndDate.push(index)
            isFormValid.value = false;
          }

          if(invoice.debit_amount  >= 1000000000 && invoice.is_policy_saved === 0){
            rules.value.debit_amount_error.show = true;
            rules.value.debit_amount_error.message = 'Incorrect amount';
            isFormValid.value = false
          }

          if(invoice.credit_amount  >= 1000000000 && invoice.is_policy_saved === 0){
              rules.value.credit_amount_error.show = true;
              rules.value.credit_amount_error.message = 'Incorrect amount';
              isFormValid.value = false
          }

          if(invoice.credit_amount  >= 1000000000 || invoice.debit_amount  >= 1000000000 && invoice.is_policy_saved === 0) {
            isFormValid.value = false
            // notif.simple("Please Note", "warning", 'Incorrect amount field entered.', 0);
          }
        }
      });
      creditIndex.value = indexCredit;
      debitIndex.value = indexDebit;
      creditIndexNo.value = indexCreditNo;
      debitIndexNo.value = indexDebitNo;
      endorsementNos.value = endorsementNo;
      amendmentStartDateRef.value = ammendmentStartDate;
      amendmentEndDateRef.value = ammendmentEndDate;
    };

    const validateSaveAsDraft = () => {
      
      let decimalPlaces = /^[0-9]*(\.[0-9]{0,2})?$/;
      let numberContain = /[0-9]/;;
      let lettersContain = /[A-Za-z]/;

      if(uploadPolicyBodyParam.chassis_number  && (!lettersContain.test(uploadPolicyBodyParam.chassis_number) || !numberContain.test(uploadPolicyBodyParam.chassis_number))) {
        rules.value.chassis.message = 'Chassis number must be alphanumeric';
        rules.value.chassis.show = true;
          isFormValid.value = false;
      }

      // if(uploadPolicyBodyParam.policy_number && !lettersContain.test(uploadPolicyBodyParam.policy_number)) {
      //   rules.value.policy_no.message = 'Policy number must have atleast one character.';
      //   rules.value.policy_no.show = true;
      //     isFormValid.value = false;
      // }

      if (uploadPolicyBodyParam.emirates_id) {
        if(uploadPolicyBodyParam.emirates_id.length > 36) {
          rules.value.emirates_id.message = 'Lebanese ID must not be greater than 36';
          rules.value.emirates_id.show = true;
          isFormValid.value = false;
        } 
        if(uploadPolicyBodyParam.emirates_id.length < 10) {
          rules.value.emirates_id.message = 'Lebanese ID must not be less than 10';
          rules.value.emirates_id.show = true;
          isFormValid.value = false;
        } 
        
        if(!numberContain.test(uploadPolicyBodyParam.emirates_id) || !lettersContain.test(uploadPolicyBodyParam.emirates_id)) {
          rules.value.emirates_id.message = 'Please enter correct Lebanese ID';
          rules.value.emirates_id.show = true;
          isFormValid.value = false;
        }
      }

        // && editPolicyObj.value.car_lead.is_vintage === 1
      // if(editPolicyObj.value && editPolicyObj.value.car_lead && parseInt(editPolicyObj.value.car_lead.car_year) < 1981) {
      //   if(uploadPolicyBodyParam.chassis_number && uploadPolicyBodyParam.chassis_number !== '' && uploadPolicyBodyParam.chassis_number.length < 9) {
      //     rules.value.chassis.message = 'Chassis number must not less than 9 and greater than 17';
      //     rules.value.chassis.show = true;
      //     isFormValid.value = false;
      //   } 
      //   if(uploadPolicyBodyParam.chassis_number && uploadPolicyBodyParam.chassis_number !== '' && uploadPolicyBodyParam.chassis_number.length > 17) {
      //     rules.value.chassis.message = 'Chassis number must not less than 9 and greater than 17';
      //     rules.value.chassis.show = true;
      //     isFormValid.value = false;
      //   } 
      // } else {
      //   if(uploadPolicyBodyParam.chassis_number && uploadPolicyBodyParam.chassis_number !== '' && uploadPolicyBodyParam.chassis_number.length < 17) {
      //     rules.value.chassis.message = 'Chassis number must not less than 17 and greater than 20';
      //     rules.value.chassis.show = true;
      //     isFormValid.value = false;
      //   } 
      //   if(uploadPolicyBodyParam.chassis_number && uploadPolicyBodyParam.chassis_number !== '' && uploadPolicyBodyParam.chassis_number.length > 20) {
      //     rules.value.chassis.message = 'Chassis number must not less than 17 and greater than 20';
      //     rules.value.chassis.show = true;
      //     isFormValid.value = false;
      //   } 
      // }

          
        if(uploadPolicyBodyParam.policy_number && uploadPolicyBodyParam.policy_number.length < 10) {
          rules.value.policy_no.message = 'Policy number must not less than 10 characters';
          rules.value.policy_no.show = true;
          isFormValid.value = false;
        }

        // if(debitValue.value > totalDebit.value) {
        //   rules.value.less_note.message = `Incorrect debit note entered (${percentage.value}%)`;
        //   rules.value.less_note.show = true;
        //   isFormValid.value = false;
        // }

        const indexCredit = [] as Array<number>;
        const indexCreditNo = [] as Array<number>;
        const indexDebit = [] as Array<number>;
        const indexDebitNo = [] as Array<number>;
        const endorsementNo = [] as Array<number>;
        editPolicyObj.value.car_lead_invoices_edit_policy.forEach((invoice, index) => {
        if(!["3", "4"].includes(invoice.amend_type)) {
          // if (invoice.credit_amount) {
          //   rules.value.credit_note.show = true;
          //   rules.value.credit_note.message = 'Credit note';
          //   indexCredit.push(index)
          //   isFormValid.value = false;
          // }
          if(invoice.credit_amount > 0 && !decimalPlaces.test(String(invoice.credit_amount))) {
            // rules.value.credit_note.show = true;
            // rules.value.credit_note.message = 'Please enter upto 2 decimal places only';
            // isFormValid.value = false
            // indexCredit.push(index)
            // isCreditValid.value = false
          }
          // if(invoice.credit_notes_no) {
          //   rules.value.credit_notes_no.show = true;
          //   rules.value.credit_notes_no.message = 'Credit note no.';
          //   isFormValid.value = false
          //   indexCreditNo.push(index)
          // }
          // to be added credit note no.
        }
        //  if (invoice.debit_amount) {
        //   rules.value.debit_note.show = true;
        //   rules.value.debit_note.message = 'Debit note';
        //   indexDebit.push(index)
        //   isFormValid.value = false;
        // }
        if(invoice.debit_amount > 0 && !decimalPlaces.test(String(invoice.debit_amount))) {
          rules.value.debit_note.show = true;
          rules.value.debit_note.message = 'Please enter upto 2 decimal places only';
          isFormValid.value = false
          indexDebit.push(index)
          isDebitValid.value = false
        }
        // if(invoice.debit_notes_no) {
        //     rules.value.debit_notes_no.show = true;
        //     rules.value.debit_notes_no.message = 'Debit note no.';
        //     isFormValid.value = false
        //     indexDebitNo.push(index)
        //   }
        // if(invoice.amend_type !== '0' && invoice.endorsement_no === null && invoice.endorsement_no === "") {
        //     rules.value.endorsement_no.show = true;
        //     rules.value.endorsement_no.message = 'Endorsement no.';
        //     isFormValid.value = false
        //     endorsementNo.push(index)
        // }
        if(["4", "2", "5"].includes(invoice.amend_type) && parseFloat(invoice.amount) > 0 &&  parseFloat(invoice.amount) < parseFloat(invoice.debit_amount)) {
          rules.value.debit_note.show = true;
          rules.value.debit_note.message = 'Debit note >  Invoice amount';
          indexDebit.push(index)
          isFormValid.value = false;
        }

          if(invoice.debit_amount  >= 1000000000){
            rules.value.debit_amount_error.show = true;
            rules.value.debit_amount_error.message = 'Incorrect amount';
            isFormValid.value = false
          }

          if(invoice.credit_amount  >= 1000000000){
              rules.value.credit_amount_error.show = true;
              rules.value.credit_amount_error.message = 'Incorrect amount';
              isFormValid.value = false
          }

        if(invoice.credit_amount  >= 1000000000 || invoice.debit_amount  >= 1000000000) {
          isFormValid.value = false
          // notif.simple("Please Note", "warning", 'Incorrect amount field entered.', 0);
        }
      });
      creditIndex.value = indexCredit;
      debitIndex.value = indexDebit;
      creditIndexNo.value = indexCreditNo;
      debitIndexNo.value = indexDebitNo;
      endorsementNos.value = endorsementNo;
    }

    const creditIndex = ref<Array<number>>([]);
    const creditIndexNo = ref<Array<number>>([]);
    const debitIndex = ref<Array<number>>([]);
    const debitIndexNo = ref<Array<number>>([]);
    const endorsementNos = ref<Array<number>>([]);
    const amendmentStartDateRef = ref<Array<number>>([]);
    const amendmentEndDateRef = ref<Array<number>>([]);
    const isCreditValid = ref(true);
    const isCreditValidNo = ref(true);
    const isDebitValid = ref(true);
    const isDebitValidNo = ref(true);
    const endorsementNo = ref(true);

    const getPolicyStartDate = () => {
       if(editPolicyObj.value.is_amended === 0) {
        setTimeout(() => {
         uploadPolicyBodyParam.policy_start_date = editPolicyObj.value.policy_start_date
          var endDateTime = new Date(
            addMonths(
              new Date(editPolicyObj.value.policy_start_date),
              12
            ).toString()
          );
          let ped = moment(endDateTime).format('YYYY-MM-DD')
          if(ped === 'Invalid date') {
            ped = ''
          }
          // if(editPolicyObj.value.status !== 4) {
            editPolicyObj.value.policy_end_date =
            policyEndDate.value =
            uploadPolicyBodyParam.policy_end_date = ped;
          // }
          }, 1000);
       }
    };

    function addMonths(date, months) {
      var d = date.getDate();
      date.setMonth(date.getMonth() + +months);
      if (date.getDate() != d) {
        date.setDate(0);
      } 
      date.setDate(date.getDate()-1);
      
      return date;
    }

    

    let showErrors = computed(() => {
      return store.getters.getPolicyErrors;
    });
    let showErrorDetails = computed(() => {
      return store.getters.getPolicyErrorDetails;
    });
    

    const showPolicydocumentsDom = computed(() => {
      return store.getters.getPolicyDomStatus;
    });
    
    

    const uploadedDocs = computed(() => {
      return store.getters.getPolicyDocs;
    })

    const disablePolicySaveButton = computed(() => {
      return store.getters.getPolicyButtonStatus;
    });
    const disablePolicyDraftSaveButton = computed(() => {
      return store.getters.getPolicyDraftButtonStatus;
    });
    let showView = computed(() => {
      const permissions = store.getters.getUserPermissions;
      return !in_array.value || isView.value
        ? true
        : false;
    });


    const disabledInput = computed(() => {
      const permissions = store.getters.getUserPermissions;
      return !in_array.value || editPolicyObj.value.status > 4 || isView.value
      // && !(permissions.is_admin || permissions.is_manager || permissions.is_underwritter) 
      ? true : false
    })

    const in_array = computed(() => {
      const data = [4, 1, 14];
        const user = store.getters.currentUser;
        return data.includes(user.role_id);
    })
    const emailLoading = ref(false)

    const emailCustomer = async() => {
        let errorCustomer = false;
      let errorBroker = false;
      emailLoading.value = true
      try {
        if(sendEmailTo.value.email_customer) {
            await PolicyService.emailCustomer({
                policy_id: route.params.policyId,
              }).then(() => {
                errorCustomer = false
                getEmailList();
              }).catch((err) => {
                errorCustomer = true
              })
        }
        if(sendEmailTo.value.email_broker) {
          await PolicyService.brokerCustomer({
            policy_id: route.params.policyId,
          }).then(() => {
            errorBroker = false;
            getEmailList();
          }).catch(() => {
            errorBroker = true
          })
        }
      } catch(error) {
        console.log('error');
        emailLoading.value = false
      } finally {
        console.log('finally');
        
        setTimeout(() => {
          emailLoading.value = false
          if(!errorCustomer && !errorBroker){
            router.push({ name: 'policy-assigned' })
          }
        }, 2000);
      }

      
    };

    const emailBroker = () => {
      Promise.all([
        PolicyService.brokerCustomer({
          policy_id: route.params.policyId,
        }),
      ]).then((data) => {
        getEmailList();
      }).catch((error) => {
        console.log(error)
      });
    };
    const getEmailList = () => {
      Promise.all([
        PolicyService.getEmailList({
          policy_id: route.params.policyId,
        }),
      ]).then((data) => {
        policyEmailList.value = store.getters.getEmailPolicyList;
      });
    };
    let disableCustomerEmailButton = computed(() => {
      return store.getters.getDisableCustomerEmailButton;
    });
    let disableBrokerEmailButton = computed(() => {
      return store.getters.getDisableBrokerButton;
    });

    const totalAmount = computed(() => {
      return editPolicyObj.value.car_lead_invoices_edit_policy.reduce((total, item) => {
        return total += item.amount
      }, 0)
    })

    const totalDebit = computed(() => {
      return editPolicyObj.value.car_lead_invoices_edit_policy.reduce((total, item) => {
        return total += parseInt(item.debit_amount)
      }, 0)
    })

    let isOpen = ref<boolean>(false);
    let closingTime = ref<boolean>(false)
    const clickDialog = ref<boolean>(false);
    

    const clickDialogHandle = () => {
      PolicyService.setDiscrepanyModal(false);
    }

    const loading = ref<boolean>(false);

    watch([showErrors, loading], ([val]) => {
      // if(val == 'You cannot upload the policy because sale price is less than debit note' && !closingTime.value) {
      //   isOpen.value = true
      // }

      // if(val == 'You cannot upload policy until the approval of accounts') {
      //   clickDialogHandle();
      // }

      // if(editPolicyObj.value.car_lead_invoice.is_discrepancy == 1) {
      //   loading.value = true
      // }
    }
    )

    const checkDisable = (invoice) => {
      if(isView.value){
        return true;
      }
      if(editPolicyObj.value?.car_lead_invoice?.approve_with_discrepancy == 1) {
        if((invoice.is_policy_saved == 0 && invoice.amend_type == "0") || showView.value) {
          return true;
        } else if (invoice.amend_type == "0" && invoice.is_policy_saved == 1) {
          return true;
        } else if (invoice.amend_type === "1") {
          return true
        } else if (editPolicyObj.value.status == 4) {
          return true
        } else {
          return false
        }
      } else {
        if(invoice.is_policy_saved == 1 || showView.value) {
          return true;
        } else if ([4, 10].includes(editPolicyObj.value.status)) {
          return true
        } else if (invoice.amend_type === "1") {
          return true
        } else if (showView.value) {
          return true;
        } else {
          return false
        }
      }
    }

    const checkCNDisable = (invoice, price) => {
      if(isView.value){
        return true;
      }
      if(editPolicyObj.value?.car_lead_invoice?.approve_with_discrepancy == 1) {
        if((invoice.is_policy_saved == 0 && invoice.amend_type == "0") || showView.value) {
          return true;
        } else if (invoice.amend_type == "0" && invoice.is_policy_saved == 1) {
          return true;
        } else if (invoice.amend_type === "1") {
          return true
        } else if (editPolicyObj.value.status == 4) {
          return true
        } else {
          return false
        }
      } else {
        if(invoice.is_policy_saved == 1 || showView.value) {
          return true;
        } else if ([4, 10].includes(editPolicyObj.value.status)) {
          return true
        } else if (invoice.amend_type === "1") {
          return true
        } else if (showView.value) {
          return true;
        } else if(['0'].includes(invoice.amend_type) && Number(invoice.amount) == Number(price)) {
          return true
        } else {
          return false
        }
      }
    }

    const sendEmailTo = ref({
      email_customer: false,
      email_broker: false,
      policy_id: route.params.policyId
    });

    const discrepancyModal = computed(() => {
      return store.getters.getDiscrepancyModal
      // return true;
    })

    const discrepancyModalText = computed(() => {
      return store.getters.getDiscrepancyModalText
    })

    const extensionAndOrangeCardChecker = computed(() => {
      let data = false;
      if(editPolicyObj.value && editPolicyObj.value.car_lead_invoices_edit_policy) {
        editPolicyObj.value.car_lead_invoices_edit_policy.forEach((invoice) => {
          if(["4", "2", "5"].includes(invoice.amend_type)) {
            data = true;
          } 
        });
      }
      return data;
    })

    const extensionAndGE = computed(() => {
      let data = false;
      if(editPolicyObj.value && editPolicyObj.value.car_lead_invoices_edit_policy) {
        editPolicyObj.value.car_lead_invoices_edit_policy.forEach((invoice) => {
          if(["2", "5"].includes(invoice.amend_type)) {
            data = true;
          } 
        });
      }
      return data;
    })

    const ocrLoading = ref(false);
    const ocr = async() => {
      editPolicyObj.value.policy_number = '';
      editPolicyObj.value.chassis_number = '';
      editPolicyObj.value.policy_start_date = '';
      editPolicyObj.value.policy_end_date = '';

      if(!policyCertificate.value || policyCertificate.value == undefined) {
        notif.simple('OCR', 'warning', 'Please upload the policy document', 0);
        return;
      }

      try {
        ocrLoading.value = true;
        const data = {
          car_insurance_policy_provider_id: editPolicyObj.value.car_insurance_policy.insurance_provider_id,
          lead_id: editPolicyObj.value.car_lead.id,
          link: policyCertificate.value.file_public_path
        };

        const result = await PolicyService.getOCR(data)
        console.log(result);
        if(result && result.status !== 500) {
          if(!result.chassisNo && !result.policyNo && !result.policyStartDate && !result.policyEndDate) {
            notif.simple('OCR', 'warning', 'OCR upload has failed. Kindly enter the Policy No., Policy Start Date & Chassis No.', 0);
            ocrLoading.value = false;
            window.scrollTo(0, 0);
            return;
          }

          if(!result.chassisNo || !result.policyNo || !result.policyStartDate) {
            notif.simple('OCR', 'warning', `
            OCR upload has been done partially. Kindly enter the missing values(${!result.policyNo ? 'Policy No.,' : ''} ${!result.policyStartDate ? 'Policy Start Date,' : ''} ${!result.chassisNo ? 'Chassis No.' : ''})
            `, 0);
            editPolicyObj.value.policy_number = result.policyNo;
            editPolicyObj.value.chassis_number = result.chassisNo;
            editPolicyObj.value.policy_start_date = result.policyStartDate;
            if(result.policyEndDate) {
              editPolicyObj.value.policy_end_date = result.policyEndDate;
            }
            if(result.policyStartDate && !result.policyEndDate) {
              getPolicyStartDate();
            }
            window.scrollTo(0, 250);
          }

          if(result.chassisNo && result.policyNo && result.policyStartDate) {
            editPolicyObj.value.policy_number = result.policyNo;
            editPolicyObj.value.chassis_number = result.chassisNo;
            editPolicyObj.value.policy_start_date = result.policyStartDate;
            if(result.policyEndDate) {
              editPolicyObj.value.policy_end_date = result.policyEndDate;
            } 
            if(result.policyStartDate && !result.policyEndDate) {
              getPolicyStartDate();
            }
          }
        }
          window.scrollTo(0, 0);
        rules.value.policy_no.show = false;
        rules.value.policy_start_date.show = false;
        rules.value.chassis.show = false;
        ocrLoading.value = false;
      } catch(err) {
        ocrLoading.value = false;
      }
    }

    const policyCertificate = computed(() => {
      if(tempDocuments.value) {
        return tempDocuments.value.find(x => x.type == 1);
      }
      return '';
    });

    const enableOcr = computed(() => {
      return editPolicyObj.value.car_insurance_policy &&
        editPolicyObj.value.is_amended === 0 &&
        editPolicyObj.value.car_insurance_policy.insurance_provider && 
        editPolicyObj.value.car_insurance_policy.insurance_provider.ocr_enabled === 1 && 
        policyCertificate.value !== undefined ? true : false;
    })

    const getBackendIncorrectDebit = computed(() => {
      return store.getters.getIsDiscrepancyAtBackEnd;
    })

    watchEffect(() => {

      if(getBackendIncorrectDebit.value) {
        rules.value.less_note.message = `Incorrect Debit Note entered (${percentage.value}%)`;
        rules.value.less_note.show = true;
        isFormValid.value = false;
        window.scrollTo(0, 250);
        
      }

      if(editPolicyObj.value && editPolicyObj.value.is_discrepancy == 1) {
        loading.value = true
      }
    })

    const resetDis = (index) => {
      store.commit(Mutations.SET_IS_DISCREPANCY_ERROR, false);
      console.log(index)
      isDebitValid.value = true; 
      rules.value.less_note.show = false; 
      const cIndex = debitIndex.value.indexOf(index);
      debitIndex.value.splice(cIndex, 1); 
    }

    const resetDistNo = (index) => {
      isDebitValidNo.value = true;
      rules.value.debit_notes_no.show = false;
      const cIndex = debitIndexNo.value.indexOf(index);
      debitIndexNo.value.splice(cIndex, 1); 
    }

    const resetEndorsement = (index) => {
      rules.value.endorsement_no.show = false;
      const cIndex = endorsementNos.value.indexOf(index);
      endorsementNos.value.splice(cIndex, 1); 
    }
    
    const showAction = (data) => {
      const user = store.getters.currentUser;
      return data.includes(user.role_id);
    }

    const insurancePayment = ref(
     [
      {
        value: "", 
        label: "Please Select Insurance Payment", 
        isDisabled: true
      },
      {
        value: 1, 
        label: "Own Account", 
        isDisabled: false
      },
      {
        value: 2, 
        label: "Payment Link Customer", 
        isDisabled: false
      },
      {
        value: 3, 
        label: "Payment Link BAI", 
        isDisabled: false
      },
      {
        value: 4, 
        label: "Intransact", 
        isDisabled: false
      },
    ]
    )

    // const customer_name = ref()
    // const emirates_id = ref()
    // const policy_no = ref()
    // const policy_start = ref()
    // const chasiss = ref()
    // const insurance_payment = ref()
    // const entity_type = ref()

    const focusField = () => {
      const { customer_name, emirates_id, policy_no, policy_start_date, chassis, insurance_payment, entity_type, credit_note, credit_notes_no, debit_note, debit_notes_no, endorsement_no, less_note } = rules.value

      if(customer_name.show) {
        const customerNameRef:any = inst?.refs?.customerNameRef
        customerNameRef.focus()
      }

      if(!customer_name.show &&
        emirates_id.show
        ) {
        const emirateIdRef:any = inst?.refs?.emirateIdRef
        emirateIdRef.isFocus()
      }

      if(!customer_name.show &&
        !emirates_id.show &&
        policy_no.show
        ) {
        const policyNoRef:any = inst?.refs?.policyNoRef
        policyNoRef.focus()
      }

      if(!customer_name.show &&
        !emirates_id.show &&
        !policy_no.show &&
        policy_start_date.show
        ) {
        const policyStartRef:any = inst?.refs?.policyStartRef
        policyStartRef.isFocus()
      }

      if(!customer_name.show &&
        !emirates_id.show &&
        !policy_no.show &&
        !policy_start_date.show &&
        chassis.show
        ) {
        const chasissRef:any = inst?.refs?.chasissRef
        chasissRef.focus()
      }

      if(!customer_name.show &&
        !emirates_id.show &&
        !policy_no.show &&
        !policy_start_date.show &&
        !chassis.show &&
        insurance_payment.show
        ) {
        const insurancePaymentRef:any = inst?.refs?.insurancePaymentRef
        insurancePaymentRef.focus()
      }

      if(!customer_name.show &&
        !emirates_id.show &&
        !policy_no.show &&
        !policy_start_date.show &&
        !chassis.show &&
        !insurance_payment.show &&
        entity_type.show
        ) {
        const enitityTypeRef:any = inst?.refs?.enitityTypeRef
        enitityTypeRef.focus()
      }

      if(!customer_name.show &&
        !emirates_id.show &&
        !policy_no.show &&
        !policy_start_date.show &&
        !chassis.show &&
        !insurance_payment.show &&
        !entity_type.show &&
        editPolicyObj.value.car_lead_invoices_edit_policy.length > 0
        ) {
        if(credit_note.show) {
          // const cnRef:any = inst?.refs?.cnRef
          // cnRef.forEach(cn => {
          //   cn.isFocus()
          // })
        }
        if(!credit_note.show &&
          credit_notes_no.show
        ) {
          const cnNoRef:any = inst?.refs?.cnNoRef
          cnNoRef.forEach(cnNo => {
            cnNo.focus()
          })
        }
        if(!credit_note.show &&
          !credit_notes_no.show &&
          (less_note.show || debit_note.show)
        ) {
          const dnRef:any = inst?.refs?.dnRef
          dnRef.forEach(dn => {
            dn.isFocus()
          })
        }
        if(!credit_note.show &&
          !credit_notes_no.show &&
          !less_note.show && 
          !debit_note.show &&
          debit_notes_no.show
        ) {
          const dnNoRef:any = inst?.refs?.dnNoRef
          dnNoRef.forEach(dnNo => {
            dnNo.focus()
          })
        }
        if(!credit_note.show &&
          !credit_notes_no.show &&
          !less_note.show &&
          !debit_note.show &&
          !debit_notes_no.show &&
          endorsement_no.show
        ) {
          const endorsementNoRef:any = inst?.refs?.endorsementNoRef
          endorsementNoRef.forEach(endorsement => {
            endorsement.focus()
          })
        }
      }
    }

    const toCustomerName = () => {
      const customerNameRef:any = inst?.refs?.customerNameRef
      customerNameRef.focus()
    }

    const setFocuToChasis = () => {
      const chasissRef:any = inst?.refs?.chasissRef
      chasissRef.focus()
    }

    const toPolicyNo = () => {
      const policyNoRef:any = inst?.refs?.policyNoRef
      policyNoRef.focus()
    }

    const toInsuranceType = () => {
      const insurancePaymentRef:any = inst?.refs?.insurancePaymentRef
      insurancePaymentRef.focus()
    }

    const toPolicyStartDate = () => {
      const policyStartRef:any = inst?.refs?.policyStartRef
      policyStartRef.isFocus()
    }
    
    const policyRestrictDates = computed(() => {
      return store.getters.getPolicyStartDateXDaysRestrict
    })
    
    const policyRestrictDatesUpcoming = computed(() => {
      return store.getters.getPolicyStartDateXDaysRestrictUpcoming
    })

    const disabledDates = computed(() => {
      const today = new Date()
      const currentDate = new Date()
      currentDate.setDate(currentDate.getDate() - policyRestrictDates.value)
      return editPolicyObj.value.status !== 4 ? moment(currentDate).format('YYYY-MM-DD') : moment(today).format('YYYY-MM-DD')
    })

    const disableUpcomingDate = computed(() => {
      const currentDate = new Date()
      currentDate.setDate(currentDate.getDate() + policyRestrictDatesUpcoming.value)
      return moment(currentDate).format('YYYY-MM-DD')
    })

    const toFirst = () => {
      setTimeout(() => {
        const discrepancyNoRef:any = inst?.refs?.discrepancyNoRef
        discrepancyNoRef.focus()
      }, 500);
    }

    const amendTypes = ["2", "4", "5"]
    const toShow = computed(() => {
      if(editPolicyObj.value && editPolicyObj.value.car_lead_invoices_edit_policy) {
        const data = editPolicyObj.value.car_lead_invoices_edit_policy.filter(x => amendTypes.includes(x.amend_type)).find(y => y.is_policy_saved === 0)
        return data ? true : false
      }
      return false;
    })

    const showExtensionModal = () => {
      PolicyService.getExtensionDocuments({
        policy_id: route.params.policyId
      });
    }

    const isAmendValid = computed(() => {
      const data = store.getters.getExtensions
      const extensions = data.filter(x => x.original_filename === null)
      return extensions.length > 0 ? false : true
    })

    const checkCompletedDocument = (value) => {
      return policyDocuments.value.find(x => x.type === value)
    }

    const getDocumentTypeNotInCompletedState = () => {
      return requiredDocument.filter((x) => {
        return !policyDocuments.value.find(y => x === y.type)
      })
    }

    const getDocumentTypeNotInCompletedStateNC = () => {
      return requiredDocumentNC.filter((x) => {
        return !policyDocuments.value.find(y => x === y.type)
      })
    }

    const checkTempDocument = (value) => {
      return tempDocs.value.find(x => x.type === value)
    }

    
    const saveASDraftLogic = computed(() => {

      // if(editPolicyObj.value.last_status === 4 && editPolicyObj.value.status === 2) return true
      if(editPolicyObj.value.is_amended === 1 || editPolicyObj.value.status === 4) return true
      
      return false;
    })

    const extensionsLogic = computed(() => {
          const data =  store.getters.getExtensions
          return data.length > 0 ? true : false
      })

      const checkPolicyView = computed(() => {
          const data =  store.getters.getPolicyView
          return data;
      })

      const calculateDN = (row) => {
        let debit_amount = 0.00
        if(editPolicyObj.value && editPolicyObj.value.car_insurance_policy) {
           debit_amount = row.debit_amount * (parseFloat(editPolicyObj.value.car_insurance_policy.cn_percent) / 100)
        }

        row.credit_amount = row.amount == 0 ? 0.00 : debit_amount.toFixed(2)

        const policy_pirce = editPolicyObj.value.car_lead_invoice ? editPolicyObj.value.car_lead_invoice.original_price : 0
        if(['0'].includes(row.amend_type) && row.amount != Number(policy_pirce)) {
          row.credit_amount = 0.00
        }

        rules.value.credit_amount_error.show = false
      }

    const isView = computed(() => {
       return (route.query?.isView === 'yes')
    })

    const editCancelModalClaim = ref()
    const clickClaimModal = () => {
      editCancelModalClaim.value.open()
      setPolicyClaim(editPolicyObj.value.car_lead_quote.quote_ref_no)
    }

    return {
      editCancelModalClaim,
      clickClaimModal,
      checkCNDisable,
      cancellationDoc,
      getTempCancellationDocuments,
      amendmentStartDateRef,
      amendmentEndDateRef,
      calculateDN,
      extensionsLogic,
      saveASDraftLogic,
      extensionAndGE,
      toCustomerName,
      isAmendValid,
      showExtensionModal,
      toShow,
      toFirst,
      policyRestrictDates,
      disableUpcomingDate,
      disabledDates,
      toPolicyStartDate,
      toInsuranceType,
      focusField,
      toPolicyNo,
      // entity_type,
      // insurance_payment,
      // chasiss,
      // policy_start,
      // policy_no,
      // customer_name,
      // emirates_id,
      roles,
      getPolicyDetails,
      showAction,
      insurancePayment,
      resetEndorsement,
      resetDistNo,
      isCreditValidNo,
      endorsementNo,
      isDebitValidNo,
      creditIndexNo,
      debitIndexNo,
      endorsementNos,
      setFocuToChasis,
      resetDis,
      getBackendIncorrectDebit,
      ocrLoading,
      enableOcr,
      policyCertificate,
      tempDocs,
      MasterService,
      ocr,
      extensionAndOrangeCardChecker,
      percentage,
      discrepancyModalText,
      discrepancyModal,
      emailLoading,
      sendEmailTo,
      creditIndex,
      debitIndex,
      isCreditValid,
      isDebitValid,
      closingTime,
      ElNotification,
      in_array,
      checkDisable,
      debitValue,
      loading,
      clickDialogHandle,
      clickDialog,
      isOpen,
      totalDebit,
      totalAmount,
      uploadedDocs,
      disabledInput,
      resetValidation,
      saveAsDraft,
      showErrorDetails,
      uploadPolicyBodyParam,
      handlePolicyFileUpload,
      handleCreditDebitFileUpload,
      handleOtherFileUpload,
      policyEndDate,
      getPolicyStartDate,
      showErrors,
      editPolicyObj,
      showPolicydocumentsDom,
      customerId,
      leadId,
      uploadPolicy,
      policyEmailList,
      rules,
      emailCustomer,
      emailBroker,
      disableCustomerEmailButton,
      disableBrokerEmailButton,
      showView,
      tempDocuments,
      policyDocuments,
      disablePolicySaveButton,
      disablePolicyDraftSaveButton,
      downloadZip,
      checkPolicyView,
      isView
    };
  },
});

